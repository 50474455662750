.edit {
  border-radius: 20px;
  width: 35%;
  max-width: 200px;
}

.editImg {
  max-width: 40px;
  min-width: 35px;
}

.backColor {
  background-color: #f6fbfb;
}

.modalHead {
  background-color: rgb(235, 231, 231);
}

.modalBody {
  border-radius: 20px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
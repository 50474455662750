@import url('../../../assets/fonts/font.css');

.menuImgDel {
  width: 50px;
}

.spicyImg {
  width: 50px;
}

/* 
.option1 {
  border-radius: 10px;
  border-bottom: 0ch;
}

.option3 {
  border-radius: 10px;
  border-top: 0px;
} */

.addProductFont {
  font-size: smaller;
}
hr {
  margin: 0;
}

.headerNav {
  display: flex;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.headerLogo {
  width: 80%;
  min-width: 90px;
  max-height: 100px;
}

.options {
  display: flex;
  align-items: center;
  margin-left: 10%;
}

.cart {
  margin-left: 10%;
}

.flag {
  min-width: 30px;
  max-height: 70px;
}

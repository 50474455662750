@import url('../../assets/fonts/font.css');

.ordersBackColor {
  background-color: #f6fbfb;
}

.ordersBtnBack {
  background-color: rgb(235, 231, 231);
}

.myaccordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: none;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.orderFont {
  font-family: SinewsSansPro;
  font-size: 17px;
}

.ordersHourFont {
  font-family: SinewsSansPro;
  font-size: 12px;
}

.orderTableFont {
  font-family: SinewsSansPro;
  font-size: 15px;
}

.orderHideFont {
  font-family: SinewsSansPro;
  font-size: 13px;
}

.processingFont {
  font-family: SinewsSansPro;
  font-size: 11px;
}
@font-face {
    font-family: 'SinewsSansPro';
    src: url('SinewsSansPro-Thin.ttf') format('truetype'),
        url('SinewsSansPro-Thin.ttf') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SinewsSansPro';
    src: url('SinewsSansPro-Light.ttf') format('truetype'),
        url('SinewsSansPro-Light.ttf') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SinewsSansPro';
    src: url('SinewsSansPro-Regular.ttf') format('truetype'),
        url('SinewsSansPro-Regular.ttf') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SinewsSansPro';
    src: url('SinewsSansPro-Medium.ttf') format('truetype'),
        url('SinewsSansPro-Medium.ttf') format('woff');
    font-weight: 600;
    font-style: normal;
}


/* Burlingame Font */

@font-face {
    font-family: 'Burlingame';
    src: url('Burlingame.ttf') format('truetype'),
        url('Burlingame.ttf') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Burlingame';
    src: url('Burlingame.ttf') format('truetype'),
        url('Burlingame.ttf') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Burlingame';
    src: url('Burlingame.ttf') format('truetype'),
        url('Burlingame.ttf') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Burlingame';
    src: url('Burlingame.ttf') format('truetype'),
        url('Burlingame.ttf') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Burlingame';
    src: url('Burlingame.ttf') format('truetype'),
        url('Burlingame.ttf') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Burlingame';
    src: url('Burlingame.ttf') format('truetype'),
        url('Burlingame.ttf') format('woff');
    font-weight: "bold";
    font-style: normal;
}
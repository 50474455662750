@import url('../../../assets/fonts/font.css');

.color {
  background-color: #507839;
}

#dropdown-variants-Success {
  width: 100%;
}

.productImg {
  width: 40%;
  height: 180px;
}

.dropdownCategory {
  border-radius: 20px;
  justify-content: start;
}


.wifi {
  background-color: #507839;
  color: white;
}

.wifiLine {
  border-bottom: 2px #507839 solid;
  margin-bottom: -2%;
}

.wifiBadge {
  background-color: #507839;
}

.productDescription {
  flex-shrink: 1;
}



.product-image {
  width: 20%;
  height: 150px;
  border-radius: 20px;
  margin-right: 20px
}


@media only screen and (max-width: 600px) {
  .product-image {
    width: 30%;
    height: 130px;
  }
}
.color {
  background-color: #507839;
}

#dropdown-variants-Success {
  width: 100%;
}

.productImg {
  width: 100%;
  height: 180px;
}

.dropdownCategory {
  border-radius: 20px;
  justify-content: start;
}

.dropItem {
}

.wifi {
  background-color: #507839;
  color: white;
}

.wifiLine {
  border-bottom: 2px #507839 solid;
  margin-bottom: -2%;
}

.wifiBadge {
  background-color: #507839;
}

.productDescription {
  flex-shrink: 1;
}

.closeIcon {
  width: 20%;
  max-width: 60px;
}

.infoIcon2 {
  width: 20%;
  max-width: 50px;
  margin: 2%;
}

.restIcon2 {
  width: 20%;
  max-width: 50px;
  margin: 2%;
}
